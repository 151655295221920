import React, { useState } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'
import DeleteBanner from '../crudOperations/deleteBanner';
import { displayRemoveAlert } from '../../../redux/slice/formUpdateSlice';
import { useDispatch } from 'react-redux';
import { getImagePath } from '../../../utils/util';
import { consoleLog } from '../../../utils/log-utils';


function BannerCategoryItem(props) {
  const dispatch=useDispatch();
  const deleteItem = () => {
    // consoleLog("item",item)
    dispatch(displayRemoveAlert({ displayRemove: true, formData: { item:props.result },type:"banner" }));
  };
  
  return (
    <>
    {
      consoleLog("option key",props.result.option_key)
    }
      
      <tr>
        <td>{props.id}</td>
        
        <td>{props.result.name}</td>
        <td><img src={getImagePath(props.result.image_path)} className="img-thumbnail" style={{ width: 75 }} /></td>

        {/* <td>{props.result.option_key}</td> */}

        {/* <td>
          <div class="form-check form-switch col-lg-12 d-flex justify-content-center">

            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              checked={props.result.status === "ACTIVE" ? true : false}

            />

          </div>

        </td> */}
        <td className="align-middle">
          <div className="d-flex justify-content-center">

            <span className="delete-icon-btn">
              <FaTrash  onClick={deleteItem} />
            </span>

          </div>
        </td>

      </tr>
    </>

  )
}

export default BannerCategoryItem