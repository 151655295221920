import axios from "axios"
import { useEffect, useState } from "react"
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig"
import { APP_VERSION_API_PATH } from "../../network/config/apiPaths"
import TableItem from "./table-item"
import { consoleLog } from "../../utils/log-utils"
import { useNavigate } from "react-router-dom"
import { FaRegEdit } from "react-icons/fa"

function AppVersionTable() {

    const [appList, setAppList] = useState([])

    const navigate=useNavigate();
    const editItem = () => {
        
        navigate("/appVersion",{state:{app:appList}});
      };
    useEffect(() => {
        appVersion();


    }, [])

    const appVersion = async () => {
        let data = await axios.get(API_CONFIG.API_HOST + APP_VERSION_API_PATH.APP_LIST_PATH, {
            headers: API_HEADERS
        })

        consoleLog("appversion", data.data.result)
        setAppList(data.data.result)
    }
    return (
        <>
            <main className="container-fluid dashboard">
                <div className="row m-3">
                    <h3>App Version</h3>
                    <div className="col-md-12 card-1 h-scroll mt-4">
                        <table className="table table-hover align-middle">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">ID</th>

                                    <th scope="col">User Android</th>
                                    <th scope="col">User Ios</th>
                                    <th scope="col">Android Minimum Version</th>
                                    <th scope="col">Ios Minimum Version</th>

                                    <th scope="col">Actions</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{appList.id}</td>
                                    <td>{appList.android_version}</td>
                                    <td>{appList.ios_version}</td>
                                    <td>{appList.android_min_version}</td>
                                    <td>{appList.ios_min_version}</td>

                                    <td className="align-middle">
                                        <p className="col-lg-12 d-flex justify-content-center">
                                            <span
                                                className="edit-icon-btn"
                                                onClick={() => {
                                                    editItem();
                                                }}
                                            >
                                                <FaRegEdit />
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                           
                        </table>
                    </div>
                </div>
            </main>
        </>
    )
}
export default AppVersionTable;