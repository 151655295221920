import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Protected from "./pages/protected-route";
import Login from "./auth/login";
import Tags from "./pages/tags/tags";
import AppVersion from "./pages/app version/appVesion";
import HomeBanners from "./pages/banners/home/home-banners";

import Reports from "./pages/repots/reports";


import User from "./pages/users/user";
import UserProfile from "./pages/users/UserProfile";
import PromoBanners from "./pages/banners/promo/promo-banner";
import AddHomeBanner from "./pages/banners/home/add-home-banner";
import AddCategoryBanner from "./pages/banners/categoryBanner/add-category-banner";
import Uom from "./pages/uom/uom";
import AddUser from "./pages/users/add-new-user";
import Dashboard from "./pages/dashboard/dashboard";
import DeliveryBoy from "./pages/users/delivery-boys/delivery";
import FranchiseOwner from "./pages/users/franchise-owner/franchise";
import StoreAdmin from "./pages/users/store-admin/store-admin";
import Customer from "./pages/users/customers/customers";
import CustomerOrders from "./pages/users/customers/customer-orders";
import CatTags from "./pages/tags/cattags";
import StoreTags from "./pages/tags/storetag";

import AppVersionTable from "./pages/app version/appVersionTable";
import FranchiseDetails from "./pages/users/franchise-owner/franchise-details";
import Deliveryorder from "./pages/users/delivery-boys/delivery-orders";
import UserEdit from "./pages/users/user-edit";
import UniqueDelivery from "./pages/users/delivery-boys/unique-delivery";
import UniqueFranchise from "./pages/users/franchise-owner/unique-franchise";


import Profile from "./pages/user-profile/profile";
import CustomerInfo from "./pages/users/customers/customer-info";
import Radio from "./pages/radio/radio";
import Poadcast from "./pages/poadcast/poadcast";
import Language from "./pages/languages/language";
import CategoryBanners from "./pages/banners/categoryBanner/category-banners";
import AddRadio from "./pages/radio/add-radio";
import AddPoadcast from "./pages/poadcast/add-poadcast";
import LocationBanners from "./pages/banners/promo/promo-banner";
import AddNewLocation from "./pages/banners/promo/add-new-location";
import PoadcastAlbum from "./pages/poadcast/poadcast-albums/poadcast-album";
import AddPoadcastAlbum from "./pages/poadcast/poadcast-albums/add-album";


export default function Routers() {
  return (
    <>
    {/* <AppNotification/> */}
    
    <Routes>
      
      <Route path="*" element={<Navigate to="/login" />} />

      <Route
        path="/categories"
      />
      <Route path="/login" element={<Login />} />

      <Route path="/dashboard" element={<Dashboard />} />



      <Route path="/tags" element={<Tags />} />

      <Route path="/cattags" element={<CatTags />} />

      <Route path="/storetags" element={<StoreTags />} />

      <Route path="/appVersionTable" element={<AppVersionTable/>} />

      <Route path="/appVersion" element={<AppVersion />} />

      <Route path="/reports" element={<Reports />} />



      <Route path="/team" element={<User />} />

      <Route path="/addNewUser" element={<AddUser />} />

      <Route path="/customerOrders" element={<CustomerOrders />} />

      <Route path="/deliveryBoys" element={<DeliveryBoy />} />

      <Route path="/deliveryOrders" element={<Deliveryorder/>}/>

      <Route path="/franchiseOwner" element={<FranchiseOwner />} />

      <Route path="storeAdmins" element={<StoreAdmin />} />

      <Route path="/customers" element={<Customer />} />

      <Route path="/userProfile" element={<UserProfile />} />

      <Route path="/homeBanners" element={<HomeBanners />} />

      <Route path="/categoryBanners" element={<CategoryBanners/>} />

      <Route path="/locationBanner" element={<LocationBanners/>} />


      <Route path="/promoBanners" element={<PromoBanners />} />

      <Route path="/addHomeBanner" element={<AddHomeBanner />} />

      <Route path="/addNewLocation" element={<AddNewLocation />} />




      <Route path="addCategoryBanner" element={<AddCategoryBanner />} />


      <Route path="/uom" element={<Uom />} />


      <Route path="/franchiseDetails" element={<FranchiseDetails/>}/>

      <Route path="/userEdit" element={<UserEdit/>}/>

      <Route path="/uniqueDelivery" element={<UniqueDelivery/>}/>

      <Route path="/uniqueFranchise" element={<UniqueFranchise/>}/>


      <Route path="/profile" element={<Profile/>}/>

      <Route path="/customerInfo" element={<CustomerInfo/>}/>
      

      <Route path="/radio" element={<Radio/>}/>

      <Route path="/poadcast" element={<Poadcast/>}/>

      <Route path="/language" element={<Language/>}/>

      <Route path="/addRadio" element={<AddRadio/>}/>

      <Route path="/addPoadcast" element={<AddPoadcast/>}/>

      <Route path="/poadcastAlbum" element={<PoadcastAlbum/>}/>

      <Route path="/addPoadcastAlbum" element={<AddPoadcastAlbum/>}/>


    </Routes>
    </>
  );
}
