import { useEffect, useRef, useState } from "react";
import { FaEdit, FaEye, FaImage, FaRegEdit, FaTag, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { deleteStatus, displayLargeImage, displayRemoveAlert, updateFormInfo } from "../../redux/slice/formUpdateSlice";
import { createUpdateAction } from "../../network/store/action/CategoryResponseAction";
import { updateMessage } from "../../redux/slice/toastSlice";
import noImage from "../../assets/no_image.jpg";
import {
  CategoryImageUplaodRequest,
  categoryImageUplaodRequest,
  languageImageUplaodRequest,
  subCategoryImageUplaodRequest,
} from "../../network/service/ImageUploadService";
import { useNavigate } from "react-router-dom";
import { isCms, isFranchisLogin } from "../../utils/userRoles";
import { API_CONFIG } from "../../network/config/ApiConfig";
import { getImagePath } from "../../utils/util";
import { consoleLog } from "../../utils/log-utils";

const LanguageItem = (props) => {
  const [product, setItem] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(product.status);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [file, setFile] = useState();
  const hiddenFileInput = useRef(null);

  const editItem = () => {
   
    consoleLog("item",product)
    dispatch(updateFormInfo({ displayForm: true, formData: { item: product } }));
    
  };

  const deleteItem = () => {
    
    dispatch(displayRemoveAlert({ displayRemove: true, formData: { item: product },type:"language" }));
  };

  const editTags = () => {
    dispatch(updateFormInfo({ displayForm: false, formData: { item: product } }));
    navigate("/ctags");
  };


  const onStatusChange = async () => {
    let status = "YES";
    if (product.status == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      status: status,
      id: product.id,
    };

    await dispatch(createUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        setItem(product, (product.status = status));
        setitemStatus(status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  const onImageClick = () => {
    hiddenFileInput.current.click();
  };

  const handleImageInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null) {
      setFile(URL.createObjectURL(fileUploaded));
      uploadImage(fileUploaded);
    }
  };

  const uploadImage = async (file) => {
    const form_data = new FormData();
    form_data.append("file", file);
    let requestBody = {
      id: product.id,
      fileData: form_data,
    };
    consoleLog("imagereq",requestBody)
    await dispatch(languageImageUplaodRequest(requestBody)).then(
      (response) => { }
    );
  };
  const showLargeImage = () => {
    dispatch(
      displayLargeImage({ displayLargeImage: true, formData: { product } })
    );
  };
  const onNgImageClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <tr key={product.id}>
      {/* <th scope="row">{od.id}</th> */}
      <td className="align-middle">
        <p>{props.sno}</p>
      </td>
      
      <td className="align-middle">
        <p>{product.name}</p>
      </td>

      <td className="align-middle">
          <div className="cat_mainwrap" onClick={onNgImageClick}>
            {
            product.image_path=== null ? null : (
              <div className="cat_mainwrap">
             {
            product.image_path === "" ? null :
              
               <div className="cat_btnwrap">
              <FaRegEdit className="faWhite" onClick={() => onNgImageClick()} />

              <FaEye className="faWhite" onClick={() => showLargeImage()} />
            </div>
             
          }

              <input
                type="file"
                onChange={handleImageInputChange}
                ref={hiddenFileInput}
                style={{ display: "none" }} // Make the file input element invisible
              />
              {file ? (
                <>
                  <img
                    src={file}
                    alt={product.name}
                    className="img-thumbnail"
                    width="50px"
                    height="50px"
                  />
                </>
              ) : product.image_path !== "" ? (
                <img
                  src={getImagePath(product.image_path)}
                  alt={product.name}
                  className="img-thumbnail"
                  width="50px"
                  height="50px"
                />
              ) : (
                <img
                  src={noImage}
                  alt={product.name}
                  className="img-thumbnail"
                  width="50px"
                  height="50px"
                />
              )}
            </div>
            )}

           
          </div>
        </td>

      <td className="align-middle">
          <div className="d-flex justify-content-center">
            <span className="edit-icon-btn">
              <FaEdit onClick={editItem}/>
            </span>
            <span className="delete-icon-btn">
              <FaTrash onClick={deleteItem}/>
            </span>
          </div>
        </td>


      

    </tr>
  );
};

export default LanguageItem;
