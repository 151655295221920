import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUpdateAction, languageCreateUpdateAction } from "../../network/store/action/CategoryResponseAction";
import {
  formUpdateData,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import { updateMessage } from "../../redux/slice/toastSlice";
import { changeStatus } from "../../redux/slice/loaderSlice";
import AppLodingBar from "../../components/loader";
import { consoleLog } from "../../utils/log-utils";

const AddUpdateForm = (props) => {
  // TO get Callbacks
  const dispatch = useDispatch();
  const formData = useSelector(formUpdateData);
  const itemInfo = formData.item;
  const [isLoading, setIsLoading] = useState(false);
  // Page Form
  const [dataForm, setDataForm] = useState({
    name: itemInfo!=null?itemInfo.name:"",
  });

  const [filterOptions, setfilterOptions] = useState({
    sectionId: "",
  });

  useEffect(() => {
    if (itemInfo) {
      let updateValues = {
        name: itemInfo.name,
      };

      setDataForm(updateValues);
    }
  }, []);

  const handleInputChanges = (e) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmtClick = async () => {
    if (!dataForm.name) {
      dispatch(updateMessage({ display: true, message: "Enter all fields" }));
      return;
    }
    dispatch(changeStatus(true));
    let requestBody = {
      name: dataForm.name,
    };

    if (itemInfo != undefined) {
      requestBody.id = itemInfo.id;
    }
    consoleLog("rbody",requestBody)

    await dispatch(languageCreateUpdateAction(requestBody)).then((response) => {
      
      consoleLog("status",response.status)
     
      consoleLog("message",response.message)
      if (response.status == true) {
        if (props.onSubmit) props.onSubmit();
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  return (
    <>
      {isLoading && <AppLodingBar />}
      <div
        style={{
          position: "fixed",
          backgroundColor: "rgba(0,0,0,0.5)",
          width: "100%",
          height: "100%",
          zIndex: "999",
          overflow: "scroll",
        }}
      >
        <div className="d-flex align-item-center justify-content-center">
          <div className="col-md-8">
            <div className="container">
              <div className="row mt-5">
                <div className="col-md-5 card-1 p-5">
                  {itemInfo != undefined ? (
                    <h4 className="text-center">Update Language</h4>
                  ) : (
                    <h4 className="text-center"> Add Language</h4>
                  )}

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={dataForm.name}
                        onChange={(e) => handleInputChanges(e)}
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 20,
                        }}
                      >
                        <button
                          style={{ display: "flex", justifyContent: "center" }}
                          type="button"
                          className="btn btn-purple-bg"
                          onClick={() => onSubmtClick()}
                        >
                          Submit
                        </button>
                        <button
                          className="btn btn-white-bg"
                          onClick={() => {
                            dispatch(
                              updateFormInfo({
                                displayForm: false,
                                formData: {},
                              })
                            );
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUpdateForm;
