import React, { useRef, useState } from 'react'
import { FaEdit, FaEye, FaRegEdit, FaTrash } from 'react-icons/fa'
import { useDispatch } from 'react-redux';
import { displayLargeImage, displayRemoveAlert, updateFormInfo } from '../../redux/slice/formUpdateSlice';
import { consoleLog } from '../../utils/log-utils';
import { getImagePath } from '../../utils/util';
import noImage from "../../assets/no_image.jpg";
import { radioImageUplaodRequest } from '../../network/service/ImageUploadService';


function RadioItem(props) {
  const dispatch=useDispatch();
  const [radio]=useState(props.result)
  const [file, setFile] = useState();
  const hiddenFileInput = useRef(null);
  consoleLog("radio",radio)
  const deleteItem = () => {
    // consoleLog("item",item)
    dispatch(displayRemoveAlert({ displayRemove: true, formData: { item:radio },type:"radio" }));
  };
  const handleImageInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null) {
      setFile(URL.createObjectURL(fileUploaded));
      uploadImage(fileUploaded);
    }
  };
  const editItem = () => {
   
    consoleLog("item",radio)
    dispatch(updateFormInfo({ displayForm: true, formData: { item: radio } }));
    
  };
  

  const uploadImage = async (file) => {
    const form_data = new FormData();
    form_data.append("file", file);
    let requestBody = {
      id: radio.id,
      fileData: form_data,
    };
    consoleLog("imagereq",requestBody)
    await dispatch(radioImageUplaodRequest(requestBody)).then(
      (response) => { }
    );
  };
  const showLargeImage = () => {
    dispatch(
      displayLargeImage({ displayLargeImage: true, formData: { radio: radio } })
    );
  };
  const onNgImageClick = () => {
    hiddenFileInput.current.click();
  };

  
  return (
    <>
    {
      consoleLog("option key",props.result.option_key)
    }
      
      <tr>
        <td>{props.id}</td>
        <td>{radio.languageName}</td>
        <td className="align-middle">
          <div className="cat_mainwrap" onClick={onNgImageClick}>
            {
           radio.image_path=== null ? null : (
              <div className="cat_mainwrap">
             {
            radio.image_path === "" ? null :
              
               <div className="cat_btnwrap">
              <FaRegEdit className="faWhite" onClick={() => onNgImageClick()} />

              <FaEye className="faWhite" onClick={() => showLargeImage()} />
            </div>
             
          }

              <input
                type="file"
                onChange={handleImageInputChange}
                ref={hiddenFileInput}
                style={{ display: "none" }} // Make the file input element invisible
              />
              {file ? (
                <>
                  <img
                    src={file}
                    alt={radio.name}
                    className="img-thumbnail"
                    width="50px"
                    height="50px"
                  />
                </>
              ) : radio.image_path !== "" ? (
                <img
                  src={getImagePath(radio.image_path)}
                  alt={radio.name}
                  className="img-thumbnail"
                  width="50px"
                  height="50px"
                />
              ) : (
                <img
                  src={noImage}
                  alt={radio.name}
                  className="img-thumbnail"
                  width="50px"
                  height="50px"
                />
              )}
            </div>
            )}

           
          </div>
        </td>        
        <td>{radio.radio_url}</td>



        {/* <td>
          <div class="form-check form-switch col-lg-12 d-flex justify-content-center">

            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              checked={props.result.status === "ACTIVE" ? true : false}

            />

          </div>

        </td> */}
        <td className="align-middle">
          <div className="d-flex justify-content-center">
            <span className="edit-icon-btn" onClick={editItem}>
              <FaEdit />
            </span>
            <span className="delete-icon-btn">
              <FaTrash onClick={deleteItem}/>
            </span>
          </div>
        </td>
       

      </tr>
    </>

  )
}

export default RadioItem