import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { deleteStatus, formUpdateStatus, updateFormInfo } from "../../redux/slice/formUpdateSlice";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { BANNER_API_PATH, POADCAST_API_PATH } from "../../network/config/apiPaths";
import { updateMessage } from "../../redux/slice/toastSlice";
import DeleteBanner from "../banners/crudOperations/deleteBanner";
import { consoleLog } from "../../utils/log-utils";
import PoadcastItem from "./poadcast-item";
import AddPoadcast from "./add-poadcast";
import { changeStatus } from "../../redux/slice/loaderSlice";

function Poadcast() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [poadcastList, setPoadcastList] = useState([]);
  const [deleteBanner, setDeleteBanner] = useState(false);
  const showDeleteAlert = useSelector(deleteStatus);
  const showRequestForm = useSelector(formUpdateStatus);
  const [isLoading, setLoading] = useState(false);
  const location=useLocation();
  const album=location.state!=null?location.state.item:"";
  consoleLog("album",album)

  useEffect(() => {
    PoadcastApi();
  }, []);

  const PoadcastApi = async () => {
    setLoading(true);
    const data = await axios.get(
      API_CONFIG.API_HOST + POADCAST_API_PATH.POADCAST_LIST+`?albumId=${album.id}`,
      {
        headers: API_HEADERS,
      }
    );
   
    consoleLog("poadcast data",data.data.result)
    setLoading(false);
    if (data.data.result != null) setPoadcastList(data.data.result);
    else{
      setPoadcastList([]);
    }
  };
  const onSubmit = () => {
    dispatch(changeStatus(false));
    dispatch(updateFormInfo({ displayForm: false, formData: {} }));
    dispatch(
      updateMessage({
        display: true,
        message: "Record updated",
      })
    );
    

    PoadcastApi();
  };
  
  consoleLog("delete banner",deleteBanner)

  return (
    <>
      {showRequestForm && (
        <AddPoadcast
          albumId={album.id}
          onSubmit={() => {
            onSubmit();
          }}
        />
      )}
      {showDeleteAlert && (
        <DeleteBanner
          onSubmit={() => {
            onSubmit();
          }}
        />
      )}
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Poadcast</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              <button
                type="button"
                className="mr-auto btn add-button-color"
                onClick={() => {
                  dispatch(
                    updateFormInfo({
                      displayForm: true,
                      formData: {},
                    })
                  );
                }}              >
                Add Poadcast
              </button>
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-12 card-1 h-scroll">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Sno</th>
                  <th scope="col">Language</th>
                  <th scope="col">Poadcast Name</th>
                  <th scope="col">Poadcast Url</th>
                  <th scope="col">Tags</th>
                  <th scope="col">Actions</th>
                  
                  
                 
                </tr>
              </thead>

              <tbody>
                {isLoading ? (
                  <>Loading ..</>
                ) : poadcastList && poadcastList.length > 0 ? (
                  <>
                    {poadcastList.map((item, index) => {
                      return (
                        <PoadcastItem
                          result={item}
                          key={item.info.id}
                          id={index + 1}
                        />
                      );
                    })}
                  </>
                ) : (
                  <> No Data</>
                )}
              </tbody>
            </table>
           {consoleLog("delete banner",deleteBanner)}


          </div>
        </div>
      </main>
    </>
  );
}

export default Poadcast;
