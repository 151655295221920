import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useToastMsg } from "../../hooks/useToastMsg";
import { BannerImageUplaodRequest } from "../../../network/service/ImageUploadService";
import AutoComplete from "react-google-autocomplete";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { STORE_API_PATH } from "../../../network/config/apiPaths";
import axios from "axios";
import { consoleLog } from "../../../utils/log-utils";

function AddNewLocation() {
  const navigate = useNavigate();
  const [inputValidation, setInputValidation] = useState({
    selectLocation: "",
    selectArea: "",
    BannerName: "",
    place_name: "",
    landmark: "",
    promoUrl: "",


  });
  const [cityName, setCity] = useState("");
  const [areaName, setAreaName] = useState("");
  const [storeResult, setStoreResult] = useState([])
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const [file, setFile] = useState("");

  // useEffect(() => {
  //   // loadData();
  // }, []);

  const dispatch = useDispatch();



  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValidation({
      ...inputValidation,
      [name]: value,
    });
  };


  const reset = () => {
    setInputValidation({
      ...inputValidation,
      selectLocation: "",
      selectArea: "",
      BannerName: "",
    });
    setAreaName("")
    setCity("")
    setFile("")
    setLatitude(0)
    setLatitude(0)
  };
  const [success, warning] = useToastMsg(
    "successfully created",
    "Requried all feilds",
    reset
  );

  const Validation = () => {
    inputValidation.selectType === "" ||
      inputValidation.selectType === "--Banner Type--" ||
      inputValidation.BannerName === "" ||
      inputValidation.image === ""
      ? warning()
      : uploadImage();
  };

  const labelStyle = {
    fontWeight: "bold",
  };

  const uploadImage = async () => {
    // let selectedOptionvalue = "";

    // if (inputValidation.selectType === "Keyword") {
    //   selectedOptionvalue = inputValidation.bannerKeyword;
    // } else if (inputValidation.selectType === "Tags") {
    //   selectedOptionvalue = inputValidation.selectOption;
    // }

    const form_data = new FormData();
    form_data.append("file", file);
    let requestBody = {
      name: inputValidation.BannerName,
     
      webUrl: inputValidation.promoUrl,
      type: "PROMO",
      fileData: form_data,
     
    };

    consoleLog("inputValidation", inputValidation)

    consoleLog("requestBody", requestBody)
    await dispatch(BannerImageUplaodRequest(requestBody)).then((response) => {

      consoleLog("response", response)
      response.data.responseCode === 200 && success()
      navigate(-1)
    });
  };

  const handleImageInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null) {
      setFile(fileUploaded);
      // uploadImage(fileUploaded);
    }
  };

  const updatePlaceInfo = (place) => {
    if (place && place.address_components != null) {
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        switch (addressType) {
          case "locality":
            setAreaName(place.address_components[i].long_name)

            break;
          case "administrative_area_level_3":
            setCity(place.address_components[i].long_name)


            break;
          // case "country":
          //   setCountry(place.address_components[i].long_name);
          //   break;
        }
      }
    }

    consoleLog("place..", place)


    setLatitude(place.geometry.location.lat())
    setLongitude(place.geometry.location.lng())
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid dashboard d-flex flex-row justify-content-center">
        <div className="row m-5 add-homeBanner-width">
          <div className=" col-12">
            {/* <div className="form-group m-0">
              <label style={labelStyle}>Search City/Town</label>{" "}
              {inputValidation.place_address}
              <AutoComplete
                className="form-control"
                style={{ width: "100%" }}
                name="landmark"
               options={{
                      types: ["geocode", "establishment"],
                    }}
                onChange={(e) => handleChange(e)}
                apiKey={"AIzaSyCB14naEHjDiVBFLEqhPBMUA9eqg_4yRdk"}
                onPlaceSelected={(place) => updatePlaceInfo(place)}
                placeholder="Enter city name"
              />
              {(areaName===""||cityName==="")&&<span className="text-danger">*Required</span>}
            </div> */}
          </div>
          {/* <div className="col-12">
            <select className="form-control">
              <option>{"--Select Location--"}</option>
              <option>Kavali</option>
              <option>Nellore</option>
              <option>Ongole</option>
              <option>Tirupati</option>
            </select>
          </div> */}
          {/* <div className="col-12">
            <select className="form-control mt-4">
              <option>{"--Select Area--"}</option>
              <option>Kavali</option>
              <option>Nellore</option>
              <option>Ongole</option>
              <option>Tirupati</option>
            </select>
          </div> */}

          <div className="col-12 mt-4">
            <label>Promo Url</label>
            <input placeholder="Promo url" name="promoUrl" type="text" value={inputValidation.promoUrl} onChange={handleChange} className="form-control" />
            {inputValidation.promoUrl === 0 && <span className="text-danger">*Required</span>}
          </div>
          <div className="col-12 mt-4">
            <label>Banner Name</label>
            <input placeholder="Banner Name" name="BannerName" value={inputValidation.BannerName} onChange={handleChange} className="form-control" />
            {inputValidation.BannerName === "" && <span className="text-danger">*Required</span>}
          </div>
          <div className="col-12">
            <label className="mt-4">Banner Image</label>
            <input
              type="file"
              className="form-control"
              onChange={handleImageInputChange}
              style={{
                border: "1px solid #80df6d",
                height: 150,
                textDecoration: "underline",
              }}
              placeholder="Browse/Upload"
            />
            {file === "" && <span className="text-danger">*Required</span>}
          </div>
          <div className="col-12 text-center">
            <button className="btn add-homeBanner-button-width mt-4" onClick={Validation}>Submit</button>
          </div>
        </div>

      </div>
    </>
  );
}

export default AddNewLocation;
