import React, { useState } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { useDispatch } from 'react-redux';
import { displayRemoveAlert, updateFormInfo } from '../../redux/slice/formUpdateSlice';
import { consoleLog } from '../../utils/log-utils';
import { getImagePath } from '../../utils/util';


function PoadcastItem(props) {
  const dispatch=useDispatch();
  const [poadcast]=useState(props.result)
  consoleLog("poadcast item",poadcast)
  const deleteItem = () => {
    consoleLog("item",poadcast)
    dispatch(displayRemoveAlert({ displayRemove: true, formData: { item: poadcast.info},type:"poadcast" }));
  };
  const editItem = () => {
   
    dispatch(updateFormInfo({ displayForm: true, formData: { item: poadcast } }));
    
  };
  
  return (
    <>
    {
      consoleLog("option key",props.result.option_key)
    }
      
      <tr>
        <td>{props.id}</td>
        <td>{poadcast.info.languageName}</td>
        <td>{poadcast.info.name}</td>

        <td>{poadcast.info.poadcast_url}</td>
        <td>{poadcast.tags&&poadcast.tags.map(i=>i.name+", ")}</td>


        {/* <td>
          <div class="form-check form-switch col-lg-12 d-flex justify-content-center">

            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              checked={props.result.status === "ACTIVE" ? true : false}

            />

          </div>

        </td> */}
       <td className="align-middle">
          <div className="d-flex justify-content-center">
            <span className="edit-icon-btn" onClick={editItem}>
              <FaEdit />
            </span>
            <span className="delete-icon-btn">
              <FaTrash onClick={deleteItem}/>
            </span>
          </div>
        </td>
       

      </tr>
    </>

  )
}

export default PoadcastItem