import { consoleLog } from "../../utils/log-utils";
import {
  PRODUCT_API_PATH,
  CATGORY_API_PATH,
  SUB_CATGORY_API_PATH,
  BANNER_API_PATH,
  COUPONS_API_PATH,
  LANGUAGE_API_PATH,
  RADIO_API_PATH,
} from "../config/apiPaths";
import { getRequest } from "./config/GetRequest";
import { postRequest } from "./config/ImageUploadRequest";

export const productImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${PRODUCT_API_PATH.PRODUCT_IMAGE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const productNxImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${PRODUCT_API_PATH.PRODUCT_NX_IMAGE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const CouponImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${COUPONS_API_PATH.IMAGE_UPLOAD}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const categoryImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${CATGORY_API_PATH.PRODUCT_IMAGE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const languageImageUplaodRequest = (data) => async () => {
  consoleLog("filedata",data.fileData)
  try {
    let result = await postRequest(
      `${LANGUAGE_API_PATH.LANGUAGE_IMAGE_UPLOAD}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const radioImageUplaodRequest = (data) => async () => {
  consoleLog("filedata",data.fileData)
  try {
    let result = await postRequest(
      `${RADIO_API_PATH.RADIO_IMAGE_UPLOAD}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const subCategoryImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${SUB_CATGORY_API_PATH.PRODUCT_IMAGE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const BannerImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${BANNER_API_PATH.CREATE_BANNER}?name=${data.name}&option=${data.option}&optionValue=${data.optionValue}&type=${data.type}&latitude=${data.latitude}&longitude=${data.longitude}&radius=${data.radius}&webUrl=${data.webUrl}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};
