import { useRef, useState } from "react";
import { FaEye, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { displayLargeImage, displayRemoveAlert, updateFormInfo } from "../../../redux/slice/formUpdateSlice";
import { consoleLog } from "../../../utils/log-utils";
import { createUpdateAction } from "../../../network/store/action/TagResponseAction";
import { updateMessage } from "../../../redux/slice/toastSlice";
import { Link, useNavigate } from "react-router-dom";
import { getImagePath } from "../../../utils/util";
import { radioImageUplaodRequest } from "../../../network/service/ImageUploadService";
import noImage from "../../../assets/no_image.jpg";


const PoadcastAlbumItem = (props) => {
  const [item] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(item.status);
  const navigate=useNavigate()
  const [file, setFile] = useState();
  const hiddenFileInput = useRef(null);

  const dispatch = useDispatch();

  const uploadImage = async (file) => {
    const form_data = new FormData();
    form_data.append("file", file);
    let requestBody = {
      id: item.id,
      fileData: form_data,
    };
    consoleLog("imagereq",requestBody)
    await dispatch(radioImageUplaodRequest(requestBody)).then(
      (response) => { }
    );
  };
  const showLargeImage = () => {
    dispatch(
      displayLargeImage({ displayLargeImage: true, formData: { item: item } })
    );
  };
  const onNgImageClick = () => {
    hiddenFileInput.current.click();
  };

  const handleImageInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null) {
      setFile(URL.createObjectURL(fileUploaded));
      uploadImage(fileUploaded);
    }
  };
  
  
  const deleteItem = () =>{
    dispatch(displayRemoveAlert({ displayRemove: true, formData: { item:item },type:"poadcastAlbum" }));
  }

  const editItem = () => {
    consoleLog("item",item);
    dispatch(updateFormInfo({ displayForm: true, formData: { item } }));
  };
  const poadcastPage=()=>{
         navigate("/poadcast",{state:{item:item}})
  }

  const onStatusChange = async () => {
    let status = "YES";
    if (item.status == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      status: status,
      id: item.id,
    };

    await dispatch(createUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        item.status = status;
        setitemStatus(status);
        consoleLog("AFTER Change", item.status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  return (
    <tr key={item.id}>
      <td className="align-middle">
        <p>{props.sno}</p>
      </td>
      <td className="align-middle" onClick={poadcastPage}>
        <Link>
        <p>{item.name}</p>
        </Link>
        
      </td>
      <td className="align-middle">
          <div className="cat_mainwrap" onClick={onNgImageClick}>
            {
           item.image_path=== null ? null : (
              <div className="cat_mainwrap">
             {
            item.image_path === "" ? null :
              
               <div className="cat_btnwrap">
              <FaRegEdit className="faWhite" onClick={() => onNgImageClick()} />

              <FaEye className="faWhite" onClick={() => showLargeImage()} />
            </div>
             
          }

              <input
                type="file"
                onChange={handleImageInputChange}
                ref={hiddenFileInput}
                style={{ display: "none" }} // Make the file input element invisible
              />
              {file ? (
                <>
                  <img
                    src={file}
                    alt={item.name}
                    className="img-thumbnail"
                    width="50px"
                    height="50px"
                  />
                </>
              ) : item.image_path !== "" ? (
                <img
                  src={getImagePath(item.image_path)}
                  alt={item.name}
                  className="img-thumbnail"
                  width="50px"
                  height="50px"
                />
              ) : (
                <img
                  src={noImage}
                  alt={item.name}
                  className="img-thumbnail"
                  width="50px"
                  height="50px"
                />
              )}
            </div>
            )}

           
          </div>
        </td> 



      <td className="align-middle">
        <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            onChange={onStatusChange}
            checked={itemStatus === "YES" ? true : false}
          />
        </div>
      </td>
      <td className="align-middle">
        <p className="col-lg-12 d-flex justify-content-center">
          <span
            className="edit-icon-btn"
            onClick={() => {
              {
                editItem();
              }
            }}
          >
            <FaRegEdit />
          </span>{" "}
          <span className="delete-icon-btn" onClick={deleteItem}>
            <FaTrash />
          </span>
        </p>
      </td>
    </tr>
  );
};

export default PoadcastAlbumItem;
