import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";

import { LANGUAGE_API_PATH, TAG_API_PATH } from "../../network/config/apiPaths";
import axios from "axios";
import { formUpdateData, updateFormInfo } from "../../redux/slice/formUpdateSlice";
import { updateMessage } from "../../redux/slice/toastSlice";
import { changeStatus } from "../../redux/slice/loaderSlice";
import { poadcastCreateUpdateAction } from "../../network/store/action/CategoryResponseAction";
import { consoleLog } from "../../utils/log-utils";
import Select from "react-select"

function AddPoadcast(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const formData = useSelector(formUpdateData);
    const itemInfo = formData.item;
    consoleLog("itemInfo", itemInfo)
    const [langList, setLangList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [inputFeilds, setInputFeilds] = useState({
        name: itemInfo != null ? itemInfo.info.name : "",
        language: itemInfo != null ? itemInfo.info.language_id : "",
        poadcastUrl: itemInfo != null ? itemInfo.info.poadcast_url : "",
        description: itemInfo != null ? itemInfo.info.description : "",
        
    })
    const[selectedTags,setSelectedTags]=useState([])
    const[selectedTagIds,setSelectedTagIds]=useState([])

    // const[ defaultIds,setDefaultIds]=useState([])
    var  defaultIds=[]
    const updateDefaultList = (options) => {
        var dOptions = [];
        var updatedDefaultIds = [];
        defaultIds.map((item, i) => {
          const defItem = options.find((element) => {
            return element.id === item;
          });
    
          if (defItem !== undefined) {
            console.log("defItem", defItem);
            dOptions.push(defItem);
          } else {
            updatedDefaultIds = defaultIds.filter(function (sitem) {
              console.log("defItem", `${sitem} --- ${item}`);
              return sitem !== item;
            });
          }
        });
    
        defaultIds = updatedDefaultIds.length > 0 ? updatedDefaultIds : defaultIds;
        setSelectedTags(dOptions);
        setSelectedTagIds(defaultIds);
      };

    const handleChange = (e) => {
        setInputFeilds({
            ...inputFeilds,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        languageApi();
        if(itemInfo!==undefined)
            itemInfo.tags.map(item=>defaultIds.push(item.id))

        tagsApi();

    }, []);

    const languageApi = async () => {
        let data = await axios.get(API_CONFIG.API_HOST + LANGUAGE_API_PATH.LANGUAGE_LIST, {
            headers: API_HEADERS
        })
        // consoleLog("language data",data.data.result);
        setLangList(data.data.result)
    };
    const tagsApi = async () => {
        let data = await axios.get(API_CONFIG.API_HOST + TAG_API_PATH.LIST_PATH, {
            headers: API_HEADERS
        })
        // consoleLog("language data",data.data.result);
        setTagList(data.data.result)
        updateDefaultList(data.data.result)
    };

    const onSubmtClick = async () => {
        if (!inputFeilds.language && !inputFeilds.poadcastUrl) {
            dispatch(updateMessage({ display: true, message: "Enter all fields" }));
            return;
        }
        dispatch(changeStatus(true));
        let requestBody = {
            poadcastUrl: inputFeilds.poadcastUrl,
            languageId: inputFeilds.language,
            name: inputFeilds.name,
            tags: selectedTagIds.toString(),
            albumId:props.albumId,
            description: inputFeilds.description
        };

        if (itemInfo != undefined) {
            requestBody.id = itemInfo.info.id;
        }
        consoleLog("rbody", requestBody)

        await dispatch(poadcastCreateUpdateAction(requestBody)).then((response) => {

            consoleLog("status", response.status)

            consoleLog("message", response.message)
            if (response.status == true) {
                if (props.onSubmit) props.onSubmit();
            } else {
                dispatch(
                    updateMessage({
                        display: true,
                        message: "Error While updating info",
                    })
                );
            }
        });
    };

    return (
        <>
            {/* {isLoading && <AppLodingBar />} */}
            <div
                style={{
                    position: "fixed",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    width: "100%",
                    height: "100%",
                    zIndex: "999",
                    overflow: "scroll",

                }}
            >
                <div className="d-flex align-item-center justify-content-center">
                    <div className="col-md-8">
                        <div className="container">
                            <div className="row mt-5">
                                <div className="col-md-5 card-1 p-5">

                                    <h4 className="text-center">Add Poadcast</h4>


                                    <div className="col-md-12">
                                        <div className="form-group">

                                            <label>
                                                Name
                                            </label>
                                            <input type="text" className="form-control" onChange={handleChange} value={inputFeilds.name} name="name" />


                                            <label>
                                                Language
                                            </label>
                                            <select className="form-control" onChange={handleChange} value={inputFeilds.language} name="language">
                                                <option value={""}>{"--Select Language--"}</option>
                                                {
                                                    langList && langList.length > 0 ?

                                                        langList.map(item => {
                                                            return (
                                                                <option value={item.id}>{item.name}</option>
                                                            )
                                                        }) : <>No Data</>
                                                }
                                            </select>

                                            <label>
                                                Poadcast Url
                                            </label>
                                            <input type="url" className="form-control" onChange={handleChange} value={inputFeilds.poadcastUrl} name="poadcastUrl" />

                                            <label>
                                                Tags
                                            </label>
                                            <Select
                                                value={selectedTags}
                                                name="selectedTags"
                                                isMulti
                                                options={tagList}
                                                getOptionLabel={(option) => `${option.name}`}
                                                getOptionValue={(option) => `${option.id}`}
                                                onChange={(sOptions) => {
                                                    let tagsId=[]
                                                    
                                                    sOptions.map(item=>tagsId.push(item.id))
                                                    consoleLog("tagsId",tagsId)
                                                    setSelectedTags( sOptions );
                                                    setSelectedTagIds(tagsId)
                                                }}

                                            />
                                            <label>
                                                Description
                                            </label>
                                            <input type="text" className="form-control" onChange={handleChange} value={inputFeilds.description} name="description" />

                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginTop: 20,
                                                }}
                                            >
                                                <button
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                    type="button"
                                                    className="btn btn-purple-bg"
                                                    onClick={onSubmtClick}

                                                >
                                                    Ok
                                                </button>
                                                <button
                                                    className="btn btn-white-bg"
                                                    onClick={() => {
                                                        dispatch(
                                                            updateFormInfo({
                                                                displayForm: false,
                                                                formData: {},
                                                            })
                                                        );
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AddPoadcast