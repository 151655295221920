import { consoleLog } from "../../../utils/log-utils";
import {
  listRequest,
  createUpdateRequest,
  categoryTagListRequest,
  updateTags,
  languageListRequest,
  radioListRequest,
  poadcastListRequest,
  poadcastAlbumListRequest,
} from "../../service/CategoryService";

/**
 *
 * @param {*} data  JSON DATA TO SEND TO SERVER
 * @returns API RESULT
 */
export const listAction = (data) => async (dispatch) => {
  const response = await dispatch(listRequest(data));
  consoleLog("",response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    consoleLog("",response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const taglistAction = (data) => async (dispatch) => {
  const response = await dispatch(categoryTagListRequest(data));
  consoleLog("",response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    consoleLog("",response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const tagUpdateAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(updateTags(requestBody));
  consoleLog("",response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    consoleLog("",response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const createUpdateAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(createUpdateRequest(requestBody));
  consoleLog("",response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    consoleLog("",response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const languageCreateUpdateAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(languageListRequest(requestBody));
  consoleLog("",response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    consoleLog("",response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const radioCreateUpdateAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(radioListRequest(requestBody));
  consoleLog("",response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    consoleLog("",response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};
export const poadcastCreateUpdateAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(poadcastListRequest(requestBody));
  consoleLog("",response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    consoleLog("",response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const poadcastAlbumCreateUpdateAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(poadcastAlbumListRequest(requestBody));
  consoleLog("",response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    consoleLog("",response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};