import { isFranchisLogin } from "../../utils/userRoles"
import AdminDashboard from "./admin-dashboard"
import FODashboard from "./fo-dashboard"

function Dashboard(){
return(
    <>
    Swara Radio
    {/* {
        isFranchisLogin()?<FODashboard/>:<AdminDashboard/>
        
    } */}
    </>
)
}
export default Dashboard