import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import {
  deleteStatus,
  displayLargeImage,
  displayRemoveAlert,
  formUpdateData,
  formUpdateStatus,
  imageUploadStatus,
  largeImageStatus,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import AddUpdateForm from "./add-update-form";
import { updateMessage } from "../../redux/slice/toastSlice";
import AppToast from "../../components/appToast";
import AppLodingBar from "../../components/loader";
import { changeStatus } from "../../redux/slice/loaderSlice";
import { listAction } from "../../network/store/action/CategoryResponseAction";
import DeleteBanner from "../banners/crudOperations/deleteBanner";
import { isCms, isFranchisLogin } from "../../utils/userRoles";
import { consoleLog } from "../../utils/log-utils";
import FullImageView from "../../components/full-image";
import LanguageItem from "./lang-item";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { LANGUAGE_API_PATH } from "../../network/config/apiPaths";
import axios from "axios";

function Language() {
  const dispatch = useDispatch();

  // list of items
  const [resultList, setResultList] = useState([]);
  const showRequestForm = useSelector(formUpdateStatus);
  const showDeleteAlert=useSelector(deleteStatus);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  const [filterList, setFilterList] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  const imageUpload = useSelector(imageUploadStatus);
  const formData = useSelector(formUpdateData);
  const displayLargeImage = useSelector(largeImageStatus);
  const [isProductUpdate, setIsProductUpdate] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    let data=await axios.get(API_CONFIG.API_HOST+LANGUAGE_API_PATH.LANGUAGE_LIST,{
      headers:API_HEADERS
    })
    consoleLog("language data",data.data.result);
    setResultList(data.data.result)
  };

  function handleSearchClick(e) {
    const searchTerm = e.target.value;
    setSearchVal(searchTerm);
    
    consoleLog("search Value", e.target.value)
    if (searchTerm === "") {
      setResultList(resultList);
      setFilterList([]);
    } else {
      const filterBySearch = resultList.filter((item) => {
        if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return item;
        }
      });
      setFilterList(filterBySearch);
    }
  }

  const onSubmit = () => {
    dispatch(changeStatus(false));
    dispatch(updateFormInfo({ displayForm: false, formData: {} }));
    dispatch(
      updateMessage({
        display: true,
        message: "Record updated",
      })
    );
    setResultList([]);
    setSearchVal("");
    setFilterList([]);

    loadData();
  };

  return (
    <>
      {showRequestForm && (
        <AddUpdateForm
          onSubmit={() => {
            onSubmit();
          }}
        />
      )}
      {showDeleteAlert && (
        <DeleteBanner 
          onSubmit={() => {
            onSubmit();
          }}
        />
      )}
      {displayLargeImage && (
        <>
          <FullImageView
            product={formData}
            onCancelClick={(reloadStatus) => {
              setIsProductUpdate(reloadStatus);
            }}
          />
        </>
      )}

      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Language</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              {
                !isFranchisLogin() && <>
                 <button
                type="button"
                className="mr-auto btn add-button-color"
                onClick={() => {
                  dispatch(
                    updateFormInfo({
                      displayForm: true,
                      formData: {},
                    })
                  );
                }}
              >
                + Add Language
              </button>
                </>
              }
              
            </div>
          </div>
        </div>
        <div className="row m-3">
          {/* <div className="col-12 col-md-3">
            <div className="form-group">
              <label>Search</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={searchVal}
                placeholder="Enter Language name"
                onChange={(e) => handleSearchClick(e)}
              />
            </div>
          </div> */}

          <div className="col-12 card-1 h-scroll">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">sno</th>
                  
                  <th scope="col">Language Name</th>
                  <th scope="col">Image</th>
                  <th scope="col">Actions</th>



                  
                 
                  
                </tr>
              </thead>
              {searchVal === "" && resultList && resultList.length > 0 ? (
                <tbody>
                  {resultList.map((item, i) => {
                    return <LanguageItem item={item} key={item.id} sno={i+1} />;
                  })}
                </tbody>
              ) : filterList.length > 0 ? (
                <tbody>
                  {filterList.map((item, i) => {
                    return <LanguageItem item={item} key={item.id} sno={i+1}/>;
                  })}
                </tbody>
              ) : (
                <p>No Data found</p>
              )}
            </table>
          </div>
        </div>
      </main>
    </>
  );
}

export default Language;
