import { useDispatch, useSelector } from "react-redux";
import { displayRemoveAlert, formUpdateData, updateFormInfo } from "../../redux/slice/formUpdateSlice";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { LANGUAGE_API_PATH } from "../../network/config/apiPaths";
import axios from "axios";
import { consoleLog } from "../../utils/log-utils";
import { updateMessage } from "../../redux/slice/toastSlice";
import { changeStatus } from "../../redux/slice/loaderSlice";
import { languageCreateUpdateAction, radioCreateUpdateAction } from "../../network/store/action/CategoryResponseAction";

function AddRadio(props) {
    const dispatch = useDispatch();
    const navigate=useNavigate();
    const formData = useSelector(formUpdateData);
    const itemInfo = formData.item;
    consoleLog("itemInfo",itemInfo)
    const [langList,setLangList]=useState([]);
    const [inputFeilds,setInputFeilds]=useState({
        language:itemInfo!=null?itemInfo.language_id:"",
        radioUrl:itemInfo!=null?itemInfo.radio_url:"",

    })

    

    useEffect(()=>{
        languageList()
    },[])

    const onSubmtClick = async () => {
        if (!inputFeilds.language && !inputFeilds.radioUrl) {
          dispatch(updateMessage({ display: true, message: "Enter all fields" }));
          return;
        }
        dispatch(changeStatus(true));
        let requestBody = {
            radioUrl:inputFeilds.radioUrl,
            languageId:inputFeilds.language,
            

        };
    
        if (itemInfo != undefined) {
          requestBody.id = itemInfo.id;
        }
        consoleLog("rbody",requestBody)
    
        await dispatch(radioCreateUpdateAction(requestBody)).then((response) => {
          
          consoleLog("status",response.status)
         
          consoleLog("message",response.message)
          if (response.status == true) {
            if (props.onSubmit) props.onSubmit();
          } else {
            dispatch(
              updateMessage({
                display: true,
                message: "Error While updating info",
              })
            );
          }
        });
      };

    const handleChange=(e)=>{
        setInputFeilds({
            ...inputFeilds,
            [e.target.name]:e.target.value
        })
    }

    const languageList=async()=>{
        let data=await axios.get(API_CONFIG.API_HOST+LANGUAGE_API_PATH.LANGUAGE_LIST,{
            headers:API_HEADERS
          })
          consoleLog("language data",data.data.result);
          setLangList(data.data.result)
    }
    return (
        <>
            {/* {isLoading && <AppLodingBar />} */}
            <div
                style={{
                    position: "fixed",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    width: "100%",
                    height: "100%",
                    zIndex: "999",
                    overflow: "scroll",

                }}
            >
                <div className="d-flex align-item-center justify-content-center">
                    <div className="col-md-8">
                        <div className="container">
                            <div className="row mt-5">
                                <div className="col-md-5 card-1 p-5">

                                {itemInfo != undefined ? (
                    <h4 className="text-center">Update Radio</h4>
                  ) : (
                    <h4 className="text-center"> Add Radio</h4>
                  )}


                                    <div className="col-md-12">
                                        <div className="form-group">

                                            <label>
                                                Language
                                            </label>
                                            <select className="form-control" onChange={handleChange} value={inputFeilds.language} name="language">
                                                <option value={""}>{"--Select Language--"}</option>
                                                {
                                                    langList&& langList.length>0?<>
                                                    {
                                                       langList.map(item=>{
                                                        return(
                                                            <option value={item.id}>{item.name}</option>
                                                        )
                                                       })
                                                    }
                                                   
                                                    </>:<>No Data</>
                                                }
                                            </select>

                                            <label>
                                                Radio Url
                                            </label>
                                            <input type="text" className="form-control" onChange={handleChange} name="radioUrl" value={inputFeilds.radioUrl} />


                                            {/* <label>
                                                Image
                                            </label>
                                            <input type="file"  className="form-control"/> */}
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginTop: 20,
                                                }}
                                            >
                                                <button
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                    type="button"
                                                    className="btn btn-purple-bg"
                                                    onClick={onSubmtClick}

                                                >
                                                    Ok
                                                </button>
                                                <button
                                                    className="btn btn-white-bg"
                                                    onClick={() => {
                                                        dispatch(
                                                          updateFormInfo({
                                                            displayForm: false,
                                                            formData: {},
                                                          })
                                                        );
                                                      }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AddRadio