export const IS_PRODUCTION = true;
export const IS_PRINT_LOG = true;


/**
 * API_CONFING, Base host and api access key
 */
export const API_CONFIG = {
  API_HOST: getApiHost(),
  ACCESS_KEY: "A31AB78E-C4C7-4C9E-AD98-6D6A1B801E45",
};

/**
 * Requst Headers
 */
export const API_HEADERS = {
  "Content-Type": "application/json",
  "api-access-key": API_CONFIG.ACCESS_KEY,
};

function getApiHost() {
  return IS_PRODUCTION
    ? "https://swara.biyss.com/"
    : "https://swara.biyss.com/";
}
